.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

/*
.nav-bgcolor-KO {
  background-color: #a5bf3e;
}
*/

.nav-bgcolor-LIFE {
  background-color: #e6660a;
}

.nav-bgcolor-DINOS {
  background-color: #e6660a;
}

.nav-bgcolor-FITNESS {
  background-color: #189dd8;
}

/* 2020-06-11 
.nav-bgcolor-LIBERTY {
  background-color: #fb1717;
}
*/

.btn-color {
  background-color: #e6660a;
}

.div-container {
  display: flex;
}

.div-fixed {
  width: 60px;
}

.div-flex-item {
  flex-grow: 1;
  text-align: left;
}

/*2021-02-01 LIFECLUB */

.servicosOnline {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

/*2021-02-18 LIFECLUB */
.servicosOnlineCentro {
  width: 100%;
  display: flex;
  justify-content: center;
}

a:link.servicosOnlineLink {
  color: white;
  background-color: transparent;
  text-decoration: none;
}
a:visited.servicosOnlineLink {
  color: white;
  background-color: transparent;
  text-decoration: none;
}
a:hover.servicosOnlineLink {
  color: black;
  background-color: transparent;
  text-decoration: underline;
}
a:active.servicosOnlineLink {
  color: pink;
  background-color: transparent;
  text-decoration: underline;
}
